import * as React from "react";
import HeadSeo from "../../components/HeadSeo.js";
import Layout from "../../components/Layout.js";
import DynamicSidebar from "../../components/DynamicSidebar";

export const Head = ({ pageContext }) => {
  const shortDescription = pageContext?.content?.description.slice(
    0,
    pageContext?.content?.description.indexOf(".")
  );

  return (
    <HeadSeo
      title={pageContext.content.title}
      description={shortDescription}
      seoImg={pageContext.content.img}
    ></HeadSeo>
  );
};

const ContentPost = ({ pageContext }) => {
  const thePageContent = pageContext.content;

  return (
    <>
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main main-backpage blog-node">
                  <div className="header-blog-image">
                    {thePageContent?.img ? (
                      <img
                        loading="lazy"
                        src={thePageContent.img}
                        alt={thePageContent?.title}
                      />
                    ) : (
                      ``
                    )}
                  </div>
                  <div>
                    <h1>{thePageContent?.title ?? null}</h1>
                    <div className="blog-body">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: thePageContent.body,
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* - - -  */}
                <DynamicSidebar filterableTitle={thePageContent?.title} />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default ContentPost;
